import React, { useEffect, useRef, useState } from 'react'

import { useRouter } from 'next/router'

import { useDispatch } from 'react-redux'

import { Flex, Box, Icon, Text } from '@chakra-ui/react'

import {FaFolderOpen, FaChartLine, FaFolder} from 'react-icons/fa'

import {BsCodeSlash} from 'react-icons/bs'

import {TiDeleteOutline} from 'react-icons/ti'

import './FoldersTree.module.css'

const toggleFolder = (e) => {
	const el = e.currentTarget
	const isexpanded = el.dataset.isexpanded == 'true'
	el.classList.add(isexpanded ? 'fa-folder' : 'fa-folder-o')
	el.classList.remove(isexpanded ? 'fa-folder-o' : 'fa-folder')
	el.dataset.isexpanded = !isexpanded

	Array.from(el.parentElement.children).forEach((el3) => {
		const els = el3.querySelectorAll(".file,.folder-container,.no-items")[0]
		if (els) {
			els.style.display = isexpanded ? 'none' : 'flex'
		}
	})
}

const Folder = ({path}) => {
	
	const ref = useRef(null)

	const [expanded, setExpanded] = useState(false)

	const isExpanded = (element) => element.dataset.isexpanded === 'true'

	useEffect(() => {
		setExpanded(isExpanded(ref.current))

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.type === 'attributes' && mutation.attributeName === 'data-isexpanded') {
					setExpanded(isExpanded(mutation.target))
			  	}
			})
		})
		observer.observe(ref.current, { attributes: true })

		return () => observer.disconnect()
	}, [])

	return (
		<Flex ref={ref} alignItems="center" marginBottom="4px" onClick={toggleFolder} className="folder fa-folder-o hoverable-opacity" data-isexpanded='true'>
			<Icon as={expanded ? FaFolderOpen : FaFolder}/>
			<Text marginLeft="10px" fontWeight="500">{path}</Text>
		</Flex>
	)
}

const File = ({endpoint, level}) => {
	const router = useRouter()

	const dispatch = useDispatch()

	const setShowDeleteDiagramDialog = (val) => dispatch({type: 'SET_SHOW_DELETE_DIAGRAM_DIALOG', showDeleteDiagramDialog: val})

	const setSelectedModelId = (id) => dispatch({type: 'SET_SELECTED_MODEL_ID', selectedModelId: id})

	return (
		<Flex alignItems='center' className="file fa-file-code-o" marginLeft={level * 20 + "px"} marginBottom="4px">
			<Icon as={BsCodeSlash}/>
			<Text className={"hoverable-opacity"} onClick={() => router.push(`/app/models/${endpoint.id}/`)} style={{marginLeft: '10px'}}>{endpoint.name !== '' ? endpoint.name : 'Untitled'}</Text>
			<Icon className={"hoverable-opacity"} as={FaChartLine} marginLeft='8px' onClick={() => {router.push(`/app/analytics/${endpoint.id}/`) }}/>
			<Icon className={"hoverable-opacity"} as={TiDeleteOutline} marginLeft='8px' onClick={() => {setSelectedModelId(endpoint.id); setShowDeleteDiagramDialog(true)}}/>
		</Flex>
	)
}

const FoldersTree = ({endpoints, level = 0}) =>
	endpoints.map((endpoint, index) => (
    	<Box key={index}>
			{endpoint.children.length ? 
				<Box className="folder-container">
					<Folder path={endpoint.name}/>
					<FoldersTree endpoints={endpoint.children} level={level + 1}/>
				</Box> : 
				<Box>
					<File endpoint={endpoint} level={level}/>
				</Box>}
   		</Box>
	))
  
export { FoldersTree }