import React, { useState } from 'react'

import * as PodelActions from '../../store/podel'

import { useDispatch } from 'react-redux'

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Box, Input, Button } from "@chakra-ui/react"

const ProjectDialog = ({isOpen, onClose}) => {

  const dispatch = useDispatch()

  const createProject = (title) => dispatch(PodelActions.createProject(title))

  const [title, setTitle] = useState('')

  const onConfirm = () => {
    createProject(title)
    onClose()
    setTitle('')
  }
  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Input name="text-input-name" value={title} onChange={e => setTitle(e.target.value)} placeholder="Project Name" width='100%'/>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} variant="ghost">Close</Button>
            <Button onClick={onConfirm} >Create</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export { ProjectDialog }