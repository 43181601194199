import React from 'react'

import { useRouter } from 'next/router'

import { useMediaQuery } from 'react-responsive'

import { Flex, Icon, Button } from '@chakra-ui/react'

const tips = ['Create a new project by clicking the \'+\' Button at the top left corner', 'Every endpoint starts with a Request node']

const tipIndex = tips ? Math.floor(Math.random() * tips.length) : null

const BottomSection = () => {
  const router = useRouter()
  const isDesktop = useMediaQuery({ query: `(min-width: 1024px)` })
  return (
    <>
      <div style={{bottom: '-4px', right: '0px', position: 'absolute', width: '100%', opacity: 0.1}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#333333" fillOpacity="1" d="M0,192L21.8,197.3C43.6,203,87,213,131,186.7C174.5,160,218,96,262,96C305.5,96,349,160,393,170.7C436.4,181,480,139,524,112C567.3,85,611,75,655,112C698.2,149,742,235,785,261.3C829.1,288,873,256,916,218.7C960,181,1004,139,1047,101.3C1090.9,64,1135,32,1178,26.7C1221.8,21,1265,43,1309,58.7C1352.7,75,1396,85,1418,90.7L1440,96L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
      </div>
      <div style={{bottom: '-4px', right: '0px', position: 'absolute', width: '100%', opacity: 0.1}}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#333333" fillOpacity="1" d="M0,160L21.8,144C43.6,128,87,96,131,96C174.5,96,218,128,262,138.7C305.5,149,349,139,393,138.7C436.4,139,480,149,524,170.7C567.3,192,611,224,655,240C698.2,256,742,256,785,234.7C829.1,213,873,171,916,181.3C960,192,1004,256,1047,240C1090.9,224,1135,128,1178,90.7C1221.8,53,1265,75,1309,90.7C1352.7,107,1396,117,1418,122.7L1440,128L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
      </div>
      <div style={{bottom: '-4px', right: '0px', position: 'absolute', width: '100%', opacity: 0.1}}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#333333" fillOpacity="1" d="M0,160L21.8,160C43.6,160,87,160,131,138.7C174.5,117,218,75,262,96C305.5,117,349,203,393,208C436.4,213,480,139,524,122.7C567.3,107,611,149,655,186.7C698.2,224,742,256,785,256C829.1,256,873,224,916,176C960,128,1004,64,1047,53.3C1090.9,43,1135,85,1178,133.3C1221.8,181,1265,235,1309,213.3C1352.7,192,1396,96,1418,48L1440,0L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path></svg>
      </div>
      {/* animation: 'fadein 2s',  */}
      <div style={{bottom: '40px', right: '80px', position: 'absolute'}}>
        <Button appearance="primary" style={{marginRight: '16px', padding: 24}} onClick={() => router.push(`/app/academy`)}><Icon icon="learning" size={16} marginRight={12}/> Academy</Button>
        <Button appearance="default" style={{padding: 24, boxShadow: 'inset 0px 0px 0px 2px rgb(51 51 51)', backgroundColor: '#e8e8e8'}} onClick={() => router.push(`/app/blocks`)}><Icon icon="multi-select" size={16} marginRight={12}/> All Blocks</Button>
      </div>
      {/* animation: 'fadein_08 2s',  */}
      {isDesktop && <div style={{bottom: '40px', left: '80px', position: 'absolute'}}>
        <div style={{padding: '10px 24px', backgroundColor: 'white', fontSize: '14px', borderRadius: '4px', opacity: 0.8}}>💡 Tip: {tips[tipIndex]}</div>
      </div>}
    </>
  )
}

const Layout = ({style, children}) => {
  return (
    <Flex flexDirection="column" style={{...style}}>
        {children}
        {/* <BottomSection/> */}
    </Flex>
  )
}

export { Layout }
