import React, { useState, useEffect } from 'react'

import { ServiceRow } from '../Shared/ServicesContainer'

import * as PodelActions from '../../store/podel'

import { useDispatch, useSelector } from 'react-redux'

import { cleanServices, servicesData } from '../../utilities/services'

import {Tabs, Flex, Box, TabList, TabPanels, Tab, TabPanel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Select, Button} from '@chakra-ui/react'

const firstCapital = (str) => str.charAt(0).toUpperCase() + str.slice(1)

const ProjectSettingsDialog = ({isOpen, onClose, onOpenDeleteDialog}) => {

  const dispatch = useDispatch()

  const [services, setServices] = useState({})

  const [selectedService, setSelectedService] = useState('firestore')

  const [title, setTitle] = useState('')

  const updateProject = (title, services) => dispatch(PodelActions.updateProject(project.id, title, services))

  const project = useSelector(state => state.podelReducer.project)
  
  const onConfirm = () => {
    updateProject(title, cleanServices(services))
    onClose()
  }

  const onDeleteProject = () => {
    onClose()
    onOpenDeleteDialog()
  }

  useEffect(() => {
    setServices(project?.services ?? {})
    setTitle(project?.title ?? '')
  }, [project])

  const props = {selectedService, servicesData, services, setServices}
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Project Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Tabs>
            <TabList>
              <Tab>General</Tab>
              <Tab>Services</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box marginBottom='10px'>
                  <Input defaultValue={title}  onChange={(e) => setTitle(e.target.value)} type="text" placeholder="Title"/>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box marginBottom='10px'>
                  <Select value={selectedService} onChange={e => setSelectedService(e.target.value)}>
                    {Object.keys(servicesData).map((service, index) => 
                      <option key={index} value={service}>{firstCapital(service)}</option>
                    )}
                  </Select>
                </Box>
                <Box marginBottom='10px'>
                  <Box>
                    {selectedService && servicesData[selectedService].map((service, index) => 
                      <ServiceRow key={index} {...props} service={{...service, name: selectedService}}/>
                    )}
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          </ModalBody>
          <Flex display="flex" padding="10px" textAlign="right">
            <Button marginRight='auto' colorScheme="red" variant="ghost" onClick={onDeleteProject}>Delete</Button> 
            <Button variant="ghost" onClick={() => onClose()}>Cancel</Button> 
            <Button onClick={onConfirm} style={{marginLeft: '10px'}}>Update</Button>
          </Flex>

        </ModalContent>
      </Modal>
  )
}

export { ProjectSettingsDialog }