import React, { useState } from 'react'

import * as PodelActions from '../../store/podel'

import { useDispatch, useSelector } from 'react-redux'

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton, Button } from "@chakra-ui/react"

import PreventPropagation from '../../components/Shared/PreventPropagation'

const DeleteProjectDialog = ({isOpen, onClose, onOpenServicesDialog}) => {

  const dispatch = useDispatch()

  const project = useSelector(state => state.podelReducer.project)

  const deleteProject = () => dispatch(PodelActions.deleteProject(project.id))

  const [deleted, setDeleted] = useState(false)


  const onDeleteProject = () => {
    deleteProject()
    setDeleted(true)
    onClose()
  }

  const onCancel = () => {
    onClose()
    if (!deleted) {
      onOpenServicesDialog()
    }
  }

  return (
    <PreventPropagation>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Delete Project</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete this project? All of its endpoints will be removed. This action can't be undone.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onDeleteProject} colorScheme="red" ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </PreventPropagation>
  )
}

export { DeleteProjectDialog }