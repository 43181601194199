const treeify = (endpoints) => {
	let result = []
	let level = {result}

	if (!endpoints) return []

	endpoints.forEach(endpoint => {
		if (!endpoint.title) {
			const path = endpoint.id
			level[path] = {result: []}
			level.result.push({id: endpoint.id, path, name, children: level[path].result})
		} else {
			endpoint.title.split('/').reduce((tree, name) => {
				const path = (name === '') ? endpoint.id : name
				if (!tree[path]) {
					tree[path] = {result: []}
					tree.result.push({id: endpoint.id, path, name, children: tree[path].result})
				}
				return tree[path]
			}, level)
		}

	})
	return result
}

export { treeify }