import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import * as PodelActions from '../../store/podel'

import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from "@chakra-ui/react"


const DeleteDiagramDialog = () => {

  const dispatch = useDispatch()

  const deleteModel = (modelId) => dispatch(PodelActions.deleteModel(modelId))

  const selectedModelId = useSelector(state => state.userReducer.selectedModelId)

  const showDeleteDiagramDialog = useSelector(state => state.pialogsReducer.showDeleteDiagramDialog)

  const setShowDeleteDiagramDialog = (val) => dispatch({type: 'SET_SHOW_DELETE_DIAGRAM_DIALOG', showDeleteDiagramDialog: val})

  const onConfirm = () => {
    deleteModel(selectedModelId)
    setShowDeleteDiagramDialog(false)
  }

  return (
    <AlertDialog
      isOpen={showDeleteDiagramDialog}
      onClose={() => setShowDeleteDiagramDialog(false)}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Diagram
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you want to delete this diagram? This action can't be undone.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button  onClick={() => setShowDeleteDiagramDialog(false)}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={onConfirm} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export { DeleteDiagramDialog }