import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { DeleteDiagramDialog } from '../../components/Pialogs/DeleteDiagramDialog'
import { ProjectDialog } from '../../components/Pialogs/ProjectDialog'
import { ProjectSettingsDialog } from '../../components/Pialogs/ProjectSettingsDialog'
import { DeleteProjectDialog } from '../../components/Pialogs/DeleteProjectDialog'
// import { EndpointDialog } from '../components/Pialogs/EndpointDialog'

import { NavBar } from '../../components/Shared/NavBar'

import * as PodelActions from '../../store/podel'

import { FoldersTree } from '../../components/Shared/FoldersTree'
import { treeify } from '../../utilities/tree'

import { useRouter } from 'next/router'

import { Layout } from '../../components/Shared/Layout'

import { Box, Flex, Select, IconButton, Icon, useDisclosure, Spinner, Text } from '@chakra-ui/react'

import { FaCog, FaPlusCircle } from 'react-icons/fa'

import { MdAddCircleOutline } from 'react-icons/md'

import { RiInboxArchiveLine } from 'react-icons/ri'

import { withAuth } from '../../components/Shared/Auth'

const FoldersTreeButton = ({title, icon, onClick}) => {
  return (
    <Flex marginBottom='8px' className="file fa-file-code-o" alignItems="center">
      <Icon as={icon} size={20}/>
      <Text className="hoverable-opacity" onClick={onClick} marginLeft="10px" fontWeight="bold">{title}</Text>
    </Flex>
  )
}

const PodelsList = () => {

  const router = useRouter()

  const dispatch = useDispatch()

  const project = useSelector(state => state.podelReducer.project)

  const podels2 = useSelector(state => state.userReducer.models) // needs work
  
  const podels = podels2?.filter(podel => podel.projectId === project?.id) ?? []

  const loadPodels = () => dispatch(PodelActions.loadModels())

  // const setShowEndpointDialog = (show) => dispatch({type: 'SET_SHOW_ENDPOINT_DIALOG', showEndpointDialog: show})

  const loadTemplateEndpoint = () => {
    if (project?.id) {
      router.push(`/app/projects/${project.id}`)
    } else {
      router.push(`/app/projects/new`)
    }
  }
  const createNewEndpoint = () => {
    dispatch(PodelActions.onCreate(router, project?.id, null))
  }

  useEffect(() => {
    loadPodels()
  }, [])

  if (podels) {
    return (
      <Box padding="60px 80px">
        <FoldersTreeButton title="New endpoint" icon={MdAddCircleOutline} onClick={createNewEndpoint}/>
        <FoldersTreeButton title="Load template" icon={RiInboxArchiveLine} onClick={loadTemplateEndpoint}/>
        <FoldersTree endpoints={treeify(podels)}/>
        <DeleteDiagramDialog/>
      </Box>
    )
  } else {
    return (
      <Flex justifyContent="center" marginTop="160px">
        <Spinner size="lg"/>
      </Flex>
    )
  }
}

const Podels = () => {

  const dispatch = useDispatch()

  const projects = useSelector(state => state.userReducer.projects)

  const project = useSelector(state => state.podelReducer.project)

  const selectedProject = useSelector(state => state.podelReducer.project)
  const setSelectedProject = (project) => dispatch({type: 'SET_PROJECT', project})
  
  const { isOpen: isOpenProjectDialog, onOpen: onOpenProjectDialog, onClose: onCloseProjectDialog } = useDisclosure()

  const { isOpen: isOpenServicesDialog, onOpen: onOpenServicesDialog, onClose: onCloseServicesDialog } = useDisclosure()

  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure()


  const loadProjects = () => dispatch(PodelActions.loadProjects())

  useEffect(() => {
    loadProjects()
  }, [])

  useEffect(() => {
    if (!project) {
      dispatch({type: 'SET_PROJECT', project: projects?.[0]})
    }
  }, [projects])
  
  return (
    <Flex flexDirection="column" flex={1} height="100%">
      <NavBar>
        <>
          {projects && projects.length > 0 && selectedProject && <Flex alignItems="center" marginLeft="20px">
            <Select flexGrow="0" flexBasis="auto" minWidth="160px" value={selectedProject.id} onChange={e => setSelectedProject(projects.find(project => project.id === e.target.value))}>
              {projects.map(project => (
                <option key={project.id} value={project.id}>{project.title ?? 'Untitled'}</option>
                ))}
            </Select>
            <IconButton icon={<Icon as={FaCog}/>} variant="ghost" marginLeft="12px" onClick={() => onOpenServicesDialog()}/>
            <IconButton icon={<Icon as={FaPlusCircle}/>} variant="ghost" marginLeft="4px" onClick={() => onOpenProjectDialog()}/>
          </Flex>}
          <ProjectDialog isOpen={isOpenProjectDialog} onClose={onCloseProjectDialog}/>
          <ProjectSettingsDialog isOpen={isOpenServicesDialog} onOpenDeleteDialog={onOpenDeleteDialog} onClose={onCloseServicesDialog}/>
          <DeleteProjectDialog isOpen={isOpenDeleteDialog} onClose={onCloseDeleteDialog} onOpenServicesDialog={onOpenServicesDialog}/>
        </>
      </NavBar>
      <Layout style={{overflow: 'scroll'}}>
        <Box>
          {(!projects) ? 
            <Flex justifyContent="center" marginTop="160px">
              <Spinner size="lg" />
            </Flex> : 
            <PodelsList/>}
        </Box>
      </Layout>
    </Flex>
  )
}

export default withAuth(Podels)
